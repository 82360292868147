<template>
	<div class="cs-block cs-blog-post-content">
		<div class="cs-base-block">
			<div class="container">
				<CSBlogPostContent :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "BlogPostContent",
	components: {
		CSBlogPostContent: () => import("../components/CSBlogPostContent.vue")
    },
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...computed('BlogPostContent'),
		hasBg() {
			if (this.data.style == 'color') return true;

			return false;
		}
	},
	mounted() {},
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-blog-post-content {
	&.cs-block {
	    padding-top: 50px !important;
	    padding-bottom: 50px !important;
	}
	
	::v-deep {
		.header-block {
			margin-bottom: 30px;
		}

		p.cs-description {
			margin-top: 30px;

			font-size: 14px;
			font-weight: 500;
			margin-bottom: $default_margin;
		}
		.content-area{
				
			.cs-image-container {
				margin-bottom: 40px;
			}
			.cs-text {
				::v-deep {
					* {
						width: 100%;
					}
				}
			}
		}   

		&-card {
			border-radius: 30px;
			background-color: #e4eef7;
			padding: 25px 30px;
		}

		&-linked {
			margin-bottom: 15px;
			text-decoration: none;
			color: #101922;
			display: block;

			&:focus,
			&:hover,
			&:focus-within,
			&:focus-visible {
				text-decoration: none;
				outline: none;
			}

			&:last-child {
				margin-bottom: 0px;
			}

			

			h6 {
				margin-bottom: 0px;
				margin-top: 15px;
				font-weight: 400;
				font-size: 14px;
				color: #101922;
			}
		}

		&-tag {
			margin-left: 2.5px;
			margin-right: 2.5px;
			margin-top: 5px;
			color: #101922;
			background-color: #fff;
			text-align: center;
			padding: 12px 25px 12px 25px;
			border-radius: 100px;
			font-size: 12px;
			font-weight: 400;
			border: 1px solid #fff;
			transition: all 300ms ease-out;

			&:focus,
			&:hover,
			&:focus-within,
			&:focus-visible {
				text-decoration: none;
				outline: none;
			}

			&:hover {
				background-color: transparent;
			}
		}
	}
}
</style>
